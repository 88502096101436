import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin, notification } from 'antd';
import { InputOTP } from 'antd-input-otp';
import axios from 'axios';
import { useRouter } from 'next/dist/client/router';
import { ReactElement, useCallback, useState } from 'react';
import xw from 'xwind';

const FormItem = Form.Item;

const Login = () => {
  const router = useRouter();
  const [showOtp, setShowOtp] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>();
  const [userId, setUserId] = useState<string>();
  const [otpForm] = Form.useForm();

  const onSubmitCredential = (values: { email: string; password: string }) => {
    const { email, password } = values;
    setLoading(true);
    axios
      .post<{ email: string, password: string}, {data: { userId: string, requireOtp: boolean}}>('/api/auth', { email, password })
      .then(({ data }) => {
        setLoading(false);
        if (!data.requireOtp) {
          router.push('/');
          return;
        }
        setUserId(data.userId);
        setShowOtp(true);
        notification.success({
          message: 'OTP Sent',
          description: 'Security code has been sent to your email.'
        });
      })
      .catch(({ response }) => {
        console.log(response);
        const msg = response?.data?.msg;
        notification.error({
          message: 'Error',
          description: msg || 'Error on login.'
        });
      })
      .finally(() => setLoading(false));
  };

  const onOtpSubmit = useCallback((values: string[]) => {
    setLoading(true);
    axios.post('/api/verify-otp', { otp: values.join(''), userId }).then(() => {
      router.push('/');
    }).catch(({ response }) => {
      console.log(response);
      notification.error({
        message: 'Error',
        description: 'Security code is invalid.'
      });
      setShowOtp(false);
      setLoading(false);
    });
  }, [userId]);

  return (
    <div css={xw`grid justify-center items-center h-screen bg-gray-100`}>
      <div css={xw`py-4 md:py-8 lg:py-8 lg:px-10 rounded-lg shadow-lg dark:bg-gray-800 px-6 md:px-10 bg-white m-auto text-center `}>
        {showOtp ? (
            isLoading 
            ? (<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />) 
            : (
              <>
              <div css={xw`self-center text-xl font-light text-gray-600 sm:text-2xl dark:text-white`}>One Time Password</div>
              <Form
                css={xw`mt-6`}
                onFinish={onOtpSubmit}
                form={otpForm}
              >
                
                <FormItem
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: 'Security code must be entered'
                    }
                  ]}
                >
                  <InputOTP autoSubmit={onOtpSubmit} inputType="numeric" disabled={isLoading} />
                </FormItem>
              </Form>
          </>
            )
         ) : (
          <>
            <div css={xw`self-center text-xl font-light text-gray-600 sm:text-2xl dark:text-white`}>Login</div>
            <Form
              css={xw`mt-6`}
              initialValues={{
                remember: true
              }}
              onFinish={onSubmitCredential}
            >
              <FormItem
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!'
                  }
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder='Email' />
              </FormItem>
              <FormItem
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!'
                  }
                ]}
              >
                <Input prefix={<LockOutlined />} type='password' placeholder='Password' />
              </FormItem>
              <FormItem>
                <FormItem name='remember' valuePropName='checked' noStyle>
                  <Checkbox>Remember me</Checkbox>
                </FormItem>

                <a href=''>Forgot password</a>
              </FormItem>
              <FormItem>
                <Button type='primary' htmlType='submit' css={xw`w-full`} loading={isLoading}>
                  Log in
                </Button>
              </FormItem>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

Login.getLayout = (page: ReactElement) => {
  return <div>{page}</div>;
};

export default Login;
